import React, {useEffect} from "react";
import { useMutation } from '@apollo/client';
import { View } from 'react-native'
import {WELCOME_CVERC} from '../network/welcome'

const Tracker = () => {
  const [welcomeGo, { data, loading, error }] = useMutation (WELCOME_CVERC)
  useEffect(()=> {
    welcomeGo()
  }, [])
  // console.log('track ', data, loading, error)
  // return (null)
}

export default Tracker