import React, {useReducer, useEffect, useState } from 'react'

const initialState = {
    firstTimeDone: false,
    // dois: [],
    // paperMetrics: [],
    // paperData: [],
    // usernames: [],
    // expertMetrics: [],
    // expertNames: [],
    // showPModal: false,
    // showEMModal: false,
    // showEModal: false,
    // lastVisited: null,
    // openSidebar: false,
}

const GlobalContext = React.createContext({
    initialState
});


function GlobalReducer(state, action){
    switch (action.type){
        case 'FIRSTTIMEDONE':
            return{
                ...state,
                firstTimeDone: action.payload,
            }
        default:
            return state;
    }
}

function GlobalProvider(props){
    const [state, dispatch] = useReducer(GlobalReducer, initialState);

    function SaveFirstTimeDone(){
        dispatch({type: 'FIRSTTIMEDONE', payload: !state.firstTimeDone});
    }

    return (
    <GlobalContext.Provider
    value={{
        SaveFirstTimeDone, firstTimeDone:state.firstTimeDone
    }}
    {...props}
    ></GlobalContext.Provider>)
}

export { GlobalContext, GlobalProvider }
