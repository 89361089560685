import React, {useReducer, useEffect, useState } from 'react'

export const TOKEN_KEY = 'secure_token'
export const HAS_LAUNCHED = 'hasLaunchedCverc';
export const ERROR_REPORT = 'error_report';
export const USER = 'user'
export const DARK = 'dark'
export const RECORD_AUDIO = 'record'
export const PROFILE = 'profile'


export const getUser = () => {
    return typeof window !== 'undefined' ? window.localStorage.getItem(USER) : null
};
export const getProfile = () => {
    return typeof window !== 'undefined' ? window.localStorage.getItem(PROFILE) : null
};
export const getDark = () => {
    return typeof window !== 'undefined' ? window.localStorage.getItem(DARK) : null
};

const initialState = {
    user: null,
    error: null,
    login: (userData) => {},
    logout: () => {},
    isDarkTheme: false,
    toggleTheme: () => {},
    profile: null,
    hasLaunched: null,
}

const AuthContext = React.createContext({
    initialState
});

function authReducer(state, action){
    switch (action.type){
        case 'LOGIN':
            return{
                ...state,
                user: action.payload,
            }
        case 'LOGOUT':
            return{
                ...state, 
                user: null,
                profile: null,
                hasLaunched: null,
            }
        case 'API_ERROR':
            return{
                ...state, 
                error: action.payload,
            }
        // case 'CHANGE_THEME':
        //     return{
        //         ...state,
        //         isDarkTheme: action.payload,
        //     }
        case 'PROFILE':
            return{
                ...state,
                profile: action.payload,
            }
        case 'HAS_LAUNCHED':
            return{
                ...state,
                hasLaunched: action.hasLaunched,
            }
        default:
            return state;
    }
}

function AuthProvider(props){
    const [state, dispatch] = useReducer(authReducer, initialState);
    const [userStored, setUserStored] = useState()
    const [profileStored, setProfileStored] = useState()
    const [hasLaunchedStored, setHasLaunchedStored] = useState()
    useEffect( () => {
        let mounted = true
        async function getStored() {
          var user = await getUser();
          var profile = await getProfile();
          if(user){setUserStored(JSON.parse(user))}
          if(profile){setProfileStored(JSON.parse(profile))}
          var hasLaunchedVar = await getHasLaunched();
          setHasLaunchedStored(hasLaunchedVar);
        //   var dark = await getDark();
        //   if(dark){dispatch({type: 'CHANGE_THEME', payload: JSON.parse(dark)});}
        }
        getStored();
        return () => mounted = false
    }, [])

    function storeProfile(profile){
        if(typeof window !== 'undefined'){
            window.localStorage.setItem(PROFILE, JSON.stringify(profile))
        }
        dispatch({type: 'PROFILE', payload: profile});
    }

    function login(userData){
        if(userData.success){
            if(typeof window !== 'undefined'){
                window.localStorage.setItem(TOKEN_KEY, userData.token) 
                window.localStorage.setItem(USER, JSON.stringify(userData))
            }
            dispatch({
                type: 'LOGIN',
                payload: userData,
            })   
        }else{
            dispatch({
                type: 'API_ERROR',
                payload: userData,
            })   
        }
    }

    function logout(){
        if(typeof window !== 'undefined'){
            window.localStorage.removeItem(TOKEN_KEY)
            window.localStorage.removeItem(USER)
            window.localStorage.removeItem(PROFILE)
            window.localStorage.removeItem(HAS_LAUNCHED)
        }
        setUserStored()
        setProfileStored()
        setHasLaunchedStored()
        dispatch({type: 'LOGOUT'});
    }

    function SaveFirstTimeDone(){
        setHasLaunched()
        dispatch({type: 'HAS_LAUNCHED', payload: !state.hasLaunched});
    }

    // function toggleTheme(isThemeColor){
    //     const newTheme = !isThemeColor;
    //     if(typeof window !== 'undefined'){
    //         window.localStorage.setItem(DARK, JSON.stringify(newTheme))
    //     }
    //     dispatch({type: 'CHANGE_THEME', payload: newTheme});
    // }

    return (
    <AuthContext.Provider 
    value={{ 
        user: userStored ? userStored : state.user, 
        profile: profileStored ? profileStored : state.profile,
        storeProfile, login, logout,
        error: state.error,
        hasLaunched: hasLaunchedStored ? hasLaunchedStored : state.hasLaunched,
        SaveFirstTimeDone
        // isDarkTheme: state.isDarkTheme, toggleTheme,
    }}
    {...props}
    ></AuthContext.Provider>)
}

export { AuthContext, AuthProvider }

export const setToken = (token) => {
    return typeof window !== 'undefined' ? window.localStorage.setItem(TOKEN_KEY, token) : null
  };

export const getToken = () => {
    return typeof window !== 'undefined' ? window.localStorage.getItem(TOKEN_KEY) : ''
};

export const deleteToken = () => {
    return typeof window !== 'undefined' ? window.localStorage.removeItem(TOKEN_KEY) : null
};

export const setErrorReport = (errorReport) => {
    return typeof window !== 'undefined' ? window.localStorage.setItem(ERROR_REPORT, errorReport) : null
  };

export const getErrorReport = () => {
    return typeof window !== 'undefined' ? window.localStorage.getItem(ERROR_REPORT) : null
};

export const deleteErrorReport = () => {
    return typeof window !== 'undefined' ? window.localStorage.removeItem(ERROR_REPORT) : null
};

export const getHasLaunched = () => {
    return typeof window !== 'undefined' ? window.localStorage.getItem(HAS_LAUNCHED) : null
};

export const setHasLaunched = () => {
    return typeof window !== 'undefined' ? window.localStorage.setItem(HAS_LAUNCHED, 'true') : null
};

export const deleteHasLaunched = () => {
    return typeof window !== 'undefined' ? window.localStorage.removeItem(HAS_LAUNCHED) : null
};


// export const getRecord = () => {
//     return typeof window !== 'undefined' ? window.localStorage.getItem(RECORD_AUDIO) : null
// };

// export const setRecord = () => {
//     return typeof window !== 'undefined' ? window.localStorage.setItem(RECORD_AUDIO, 'true') : null
// };