
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useMutation, } from '@apollo/client';
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFormik } from 'formik';
import * as yup from 'yup';
import { MdOutlineEmail, MdVerifiedUser } from 'react-icons/md';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { IconContext } from "react-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Sentry } from "react-activity";
import "react-activity/dist/Sentry.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { RoutesNames } from "../../routes";

import { LOGIN_USER, LOGIN_USER_EMAIL } from '../../network/user';
import { AuthContext } from '../../config/auth';
import { notifConfig, } from '../../components/helpers';
import { ICON_SIZE } from '../../styles/global';
// import { GlobalContext } from '../../config/globalstates';

const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});

const LoginFormSchema = yup.object ({
  username: yup.string ().required ('Username or email is required'),
  password: yup.string (),
});

export const Signin = () => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next")
  // console.log('signin next', next)

  let history = useNavigate();
  const context = useContext(AuthContext)

  const [ errors, setErrors ] = useState();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const TogglePasswordVisible = () => {setPasswordVisible(!passwordVisible)}
  const [withEmail, setWithEmail] = useState(false)
  const ToggleWithEmail = () => {setWithEmail(!withEmail)}
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [socialLogin, setSocialLogin] = useState([]);

  const [loginUser, {data, loading, error }] = useMutation (LOGIN_USER, {
    update(_, {data: { tokenAuth: userData}}){
      context.login(userData)
    },
    onError(err){
      setErrors(err);
    },
  });

  const [loginUserEmail, {data:datae, loading:loadinge, error:errore }] = useMutation (LOGIN_USER_EMAIL, {
    update(_, {data: { tokenAuth: userData}}){
      context.login(userData)
    },
    onError(err){
      setErrors(err);
    },
  });

  useEffect(() => {
    let mounted = true;
    if(data?.tokenAuth?.errors?.nonFieldErrors){
      var errorH = data.tokenAuth.errors.nonFieldErrors[0].message.replace('.', '')
      var errorH1 = errorH
      setErrors(errorH1)
    }
    if(datae?.tokenAuth?.errors?.nonFieldErrors){
      var errorH = datae.tokenAuth.errors.nonFieldErrors[0].message.replace('.', '')
      var errorH1 = errorH
      setErrors(errorH1)
    }
    return () => {mounted = false}
  }, [data, datae])

  useEffect(() => {
    if(errors){
      notifyError(errors)
      setErrors()
    }
    return () => {}
  }, [errors])

  useEffect(() => {
    let mounted = true;
    if(context.user){
      if(next){
        history(next, { state: { source: 'sign in' } } )
      }else {
        history(RoutesNames.Cttavr.path, { state: { source: 'sign in' } } )
      }
    }
    return () => {mounted = false}
  }, [context.user])

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginFormSchema,
    onSubmit: (values, actions) => {
      // console.log("form ", values)
      setUsername(values.username)
      setPassword(values.password)
      if(withEmail){
        loginUserEmail({variables: {
                email:values.username, 
                password:values.password}}
                )
      }else{
        loginUser({variables: {
                username:values.username, 
                password:values.password,
              }}
                )
      }
    },
  });

  // console.log('sign in page 1', data)
  return (
    <main>
      <ToastContainer/>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={RoutesNames.Presentation.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to Home
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" 
          >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Medical Intelligence</h3>
                </div>
                
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Log In</h3>
                </div>

            <Form className="mt-4" onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-4">
                {/* <Form.Label htmlFor="username">Username</Form.Label> */}
                <InputGroup>
                  <InputGroup.Text>
                    <span style={{  padding:5, cursor:'pointer', }} 
                      onClick={ToggleWithEmail}>
                    <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                    {withEmail ? <MdOutlineEmail/> : <MdVerifiedUser/>}
                    </IconContext.Provider>
                    </span>
                  </InputGroup.Text>
                  <Form.Control autoFocus required type={withEmail ? "email" : "text"} 
                  placeholder={withEmail ? "example@company.com" : "Username"} id="username"
                     {...formik.getFieldProps('username')}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Group className="mb-4">
                  {/* <Form.Label htmlFor="password">Your Password</Form.Label> */}
                  <InputGroup>
                    <InputGroup.Text >
                    <span style={{  padding:5, cursor:'pointer', }} 
                      onClick={TogglePasswordVisible}>
                    <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                    {passwordVisible ? <AiFillEyeInvisible/> : <AiFillEye/>}
                    </IconContext.Provider>
                    </span>
                    </InputGroup.Text>
                    <Form.Control required type={passwordVisible ? 'text' : 'password'} placeholder="Password" id="password"
                      {...formik.getFieldProps('password')}
                    />
                  </InputGroup>
                  {formik.touched.password && formik.errors.password ? (
                  <p style={{ color:'red', textAlign:'center', }}>{formik.errors.password}</p>
                ) : null}
                </Form.Group>
              </Form.Group>

              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                <Button disabled={loading || loadinge ? true : false } 
                color="primary" variant="contained"
                type="submit"
                 > 
                    {loading || loadinge ? <Sentry /> : "Log In"}
                </Button>
              </Box>

            </Form>

              <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    <Card.Link href={"https://www.medniscientai.com/password-reset"} target='_blank' className="fw-bold">
                      {` Forgot password? `}
                    </Card.Link>
                  </span>
                </div>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Don't have an account yet?
                  </span>
                </div>

                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                  <Button
                  color="primary" variant="contained"
                  type="submit"
                  // onClick={() => {history(RoutesNames.Signup.path)}}
                  onClick={() => {
                        if(next){
                          history(RoutesNames.Signup.path + '?next=' + next) 
                        } else {
                          history(RoutesNames.Signup.path)
                        }
                      }
                    }
                  > 
                    Sign Up
                  </Button>
                </Box>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
