import React, { useState, useEffect, } from "react";

import { Col, Row, Container, } from '@themesberg/react-bootstrap';
import { Button, } from '@mui/material';

import { FooterCustom } from  "./FooterCustom";
import ResponsiveAppBar from "./ResponsiveAppBar";

import { TestimonialCard } from "../../components/elements";


export const Team = () => {

    return (
      <>
        <ResponsiveAppBar />
  
        <br />
        <br />

        <section className="section">
        <Container>

            <h2 className="text-center p-2" style={{ color:'black', }}>World class healthcare technologists team</h2>
            <br/>
            <br/>

            <Row className="justify-content-center">

            <Col lg="12">
                <Row className="row-grid">
                <Col lg="4">
                    <TestimonialCard image={'22_povertchouk.png'} name={'Pavel Overtchouk MD'} text={'Pavel is an AI engineer, physician, interventional cardiologist, researcher and full-stack developer. He is the founder and CEO of alviss.ai.'} />
                </Col>
                <Col lg="4">
                    <TestimonialCard image={'susan.jpeg'} name={'Susan Stretesky'} text={'Business development. Seasoned CV surgery revenue & go-to-market executive with 20+ years of experience. Former Medtronic sales leader.'} />
                </Col>
                <Col lg="4">
                    <TestimonialCard image={'sunny.jpeg'} name={'Sunny Nadolsky'} text={'Seasoned healthcare business executive and advisor with 20+ years of experience. VC. Board member in multiple tech companies.'} />
                </Col>
                <Col lg="4">
                    <TestimonialCard image={'srini.jpeg'} name={'Srinivas Achukola'} text={'Seasoned healthcare CTO, VP and operations advisor with 20+ years of experience. VC. Board member in multiple tech companies.'} />
                </Col>
                <Col lg="4">
                    <TestimonialCard image={'tokuno.png'} name={'Taishi Okuno MD'} text={'Mitsui Memorial University Hospital (Japan). Physician, interventional cardiologist, academic. Dr. Okuno is an advisor and research lead.'} />
                </Col>
                </Row>
            </Col>
            </Row>
        </Container>
        </section>  
  
        <FooterCustom />
  
      </>
    );
  };