import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, } from "react-router-dom";
import { TourProvider } from '@reactour/tour'
import { GoogleOAuthProvider } from '@react-oauth/google';

// core styles
import "./scss/volt.scss";
// import "typeface-roboto";

// vendor styles
import "@fortawesome/fontawesome-free/css/all.css";
import "react-datetime/css/react-datetime.css";

import { client } from './config/client';
import { ApolloProvider} from '@apollo/client';
import { GlobalProvider } from './config/globalstates';
import { AuthProvider } from './config/auth';

import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';

// import HomePage from "./pages/HomePage";
import { HomePageRouting } from './pages/HomePage';
import ScrollToTop from "./components/ScrollToTop";
import { steps, calcPosition } from './components/tour';
import './index.css';
import { GOOGLE_AUTH_CLIENT_ID } from './config/config';


ReactDOM.render(
  <HashRouter>
    <ScrollToTop />
    <I18nextProvider i18n={ i18n }>
    <ApolloProvider client={client}>
      <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>
      <AuthProvider>
        <GlobalProvider>
          <TourProvider steps={steps} position={calcPosition}>
          <HomePageRouting />
          </TourProvider>
        </GlobalProvider>
      </AuthProvider>
      </GoogleOAuthProvider>
    </ApolloProvider>
    </I18nextProvider>
  </HashRouter>,
  document.getElementById("root")
);
