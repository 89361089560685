export const PRIMARY = '#EAF1F8';
// export const SECONDARY = '#FCFCFC';
export const SECONDARY = '#8db1d8';
export const FONT = '#424242';
export const BACKGROUND = 'rgba (246, 246, 250, 0.904)';
export const HEADER = 'white';
export const CONTAINER = 'white';
export const BUTTON = '#00c7e4'; 
export const BUTTON_HEAVY = '#1491a5';

// ACTIONS
export const SUCCESS = '#00cc00';
export const WARNING = '#ffae00';
export const ALERT = '#cc4b37';
