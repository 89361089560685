import * as React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import sectraexport1 from '../../assets/img/brand/sectra_ea_step_1.png';
import sectraexport2 from '../../assets/img/brand/sectra_ea_step_2.png';
import { Button, } from '@mui/material';


export const ExportModal = (show) => {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if(show){
      setOpen(show?.show)
    }
  }, [show?.show])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
  //   border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 4,
    overflow:'scroll',
    overflowY: "scroll",
};
  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
            <p style={{ textAlign:'center', fontWeight:'bold', }}>How to export and anonymize images in your PACS?</p>
            <p>Export can often be found by clicking on File in the top left corner, or with a Right Click on the image series in your PACS. Then Anonymize is a feature you can check.</p>
            <p style={{ textAlign:'center', fontWeight:'bold', }}>How to Export-Anonymize in Sectra</p>
            <img
            alt="Sectra Export Anonymize 1"
            className="img-center img-fluid shadow shadow-lg--hover"
            src={sectraexport1}
            // style={{ width:300 }}
            />
            <br/>
            <img
            alt="Sectra Export Anonymize 2"
            className="img-center img-fluid shadow shadow-lg--hover"
            src={sectraexport2}
            // style={{ width:300 }}
            />
            <br/>
            <br/>
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                <Button variant='contained' onClick={() => handleClose() }>Close</Button>
            </Box>

        </Box>
      </Modal>
    </div>
  )
}

