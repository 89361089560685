import React, { useState, } from "react";
import { Col, Row, Card, } from '@themesberg/react-bootstrap';
import Modal from 'react-modal';

import { IconContext } from "react-icons";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { EmailShareButton, FacebookShareButton, PinterestShareButton, RedditShareButton, 
    TelegramShareButton, TumblrShareButton, TwitterShareButton, WhatsappShareButton, 
    WeiboShareButton, LinkedinShareButton, 
    EmailIcon, FacebookIcon, PinterestIcon, RedditIcon, 
    TelegramIcon, TumblrIcon, TwitterIcon, WhatsappIcon, WeiboIcon, LinkedinIcon, 
  } from "react-share";
import { BsShareFill, } from 'react-icons/bs';
import moment from "moment-timezone";

import { modalStyles } from "../../components/helpers";
import { ICON_SIZE } from "../../styles/global";


export const FooterCustom = () => {
const currentYear = moment().get("year");
const [openShareModal, setOpenShareModal] = useState(false)
  var LINK_CV = "https://www.medniscientai.com/"
  var QUOTE_CV = "Use #ai powered #predictivemedicine to support your decisions! With Medniscient AI #medtech #alviss #digitalhealth"
  var SHARE_ICON_SIZE = 50
  var styleShareButton = { margin:10, }
  var EMAIL_SUBJECT = "Discover Medniscient AI"

  return (
    <footer className="footer section py-5 px-2">

    <Modal
        isOpen={openShareModal}
        onRequestClose={()=>setOpenShareModal(false)}
        style={modalStyles}
        contentLabel="Metrics"
        >
        <div style={{ flex:1, justifyContent:'center', alignItems:'center', }}>
            <div style={{ position:'absolute', top:-25, right:-20, zIndex:1000, 
                backgroundColor:'transparent', }}>
                <span style={{ padding:10, cursor:'pointer', }} onClick={()=>setOpenShareModal(false)}>
                <IconContext.Provider value={{ color: 'black', size:ICON_SIZE, }} >
                    <AiOutlineCloseCircle />
                </IconContext.Provider>
                </span>
            </div>
            <div>Share to: </div>
            <div  style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <EmailShareButton url={LINK_CV} subject={EMAIL_SUBJECT} body={QUOTE_CV} 
            style={styleShareButton}
            >
                <EmailIcon size={SHARE_ICON_SIZE} round={true} />
            </EmailShareButton>
            <LinkedinShareButton url={LINK_CV} source={LINK_CV} title={"Interesting CV"} summary={QUOTE_CV} 
            style={styleShareButton}>
                <LinkedinIcon size={SHARE_ICON_SIZE} round={true} />
            </LinkedinShareButton>
            <TwitterShareButton  url={LINK_CV} title={QUOTE_CV} hashtags={["alvisscv"]}
            style={styleShareButton}>
                <TwitterIcon size={SHARE_ICON_SIZE} round={true} />
            </TwitterShareButton>
            <FacebookShareButton url={LINK_CV} quote={QUOTE_CV} hashtag="alvisscv" 
            style={styleShareButton}>
                <FacebookIcon size={SHARE_ICON_SIZE} round={true} />
            </FacebookShareButton>
            <PinterestShareButton url={LINK_CV}
                media={'https://www.alviss.ai/static/images/alviss_white.png'} 
                description={QUOTE_CV} style={styleShareButton}>
                <PinterestIcon size={SHARE_ICON_SIZE} round={true} />
            </PinterestShareButton>
            <RedditShareButton url={LINK_CV} title={QUOTE_CV} style={styleShareButton}>
                <RedditIcon size={SHARE_ICON_SIZE} round={true} />
            </RedditShareButton>
            <TumblrShareButton url={LINK_CV} title={"Interesting CV"} caption={QUOTE_CV} 
            style={styleShareButton}>
                <TumblrIcon size={SHARE_ICON_SIZE} round={true} />
            </TumblrShareButton>
            <TelegramShareButton url={LINK_CV} title={QUOTE_CV} style={styleShareButton}>
                <TelegramIcon size={SHARE_ICON_SIZE} round={true} />
            </TelegramShareButton>
            <WhatsappShareButton url={LINK_CV} title={QUOTE_CV} style={styleShareButton}>
                <WhatsappIcon size={SHARE_ICON_SIZE} round={true} />
            </WhatsappShareButton>
            <WeiboShareButton url={LINK_CV}
                title={QUOTE_CV} 
                image={"https://www.alviss.ai/static/images/alviss_white.png"} 
                style={styleShareButton}
                >
                <WeiboIcon size={SHARE_ICON_SIZE} round={true} />
            </WeiboShareButton>
            </div>
          </div>
        </Modal>

        <Row>
          <Col xs={12} lg={6} className="mb-4 mb-lg-0">
            <p className="mb-0 text-center text-xl-left">
              Copyright © 2021-{`${currentYear} `}
              <Card.Link href="https://www.medniscientai.com" target="_blank" className="text-blue text-decoration-none fw-normal">
                Medniscient AI
            </Card.Link>
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <ul className="list-inline list-group-flush list-group-borderless text-center text-xl-right mb-0">
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://www.medniscientai.com" target="_blank">
                  Commercial page
              </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://www.medniscientai.com/#/aipe" target="_blank">
                  AI PE
              </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://www.medniscientai.com/#/bookdemo" target="_blank">
                  Contact us
              </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://www.medniscientai.com/#/press" target="_blank">
                  Press
              </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://www.medniscientai.com/#/terms" target="_blank">
                  Terms
              </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <Card.Link href="https://www.medniscientai.com/#/privacy" target="_blank">
                  Privacy
              </Card.Link>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <span onClick={()=>setOpenShareModal(true)} style={{ cursor:"pointer", marginRight:20, }}>
                <IconContext.Provider value={{ color: 'black', size:30}}>
                    <BsShareFill/>
                </IconContext.Provider>
                </span>
              </li>
            </ul>
          </Col>
        </Row>
      </footer>
    )
}

