
import React, { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { View, Text, Linking, TouchableOpacity, Platform, Alert, StyleSheet } from 'react-native';
import { useMutation, } from '@apollo/client';
import { useNavigate, useSearchParams } from "react-router-dom";

import { useFormik, Formik } from 'formik';
import * as yup from 'yup';
import { MdOutlineEmail, MdVerifiedUser } from 'react-icons/md';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { IconContext } from "react-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Sentry } from "react-activity";
import "react-activity/dist/Sentry.css";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faSignInAlt, } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { RoutesNames } from "../../routes";

import { REGISTER_USER, LOGIN_USER } from '../../network/user';
import { AuthContext } from '../../config/auth';
import { notifConfig } from '../../components/helpers';
import { ICON_SIZE } from '../../styles/global';
import { GlobalContext } from '../../config/globalstates';

const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});

const SignUpFormSchema = yup.object ({
  username: yup.string ().required ('Username is required'),
  email: yup.string ().email ().required ('Email is required'),
  password1: yup.string ().required ('Password is required'),
  password2: yup
    .string ()
    .oneOf ([yup.ref ('password1'), null], 'Passwords must match')
    .required ('Password confirmation is required'),
});

export const Signup = () => {
  const [searchParams] = useSearchParams();
  const next = searchParams.get("next")

  let history = useNavigate();
  const context = useContext(AuthContext)
  const { lastVisited, changeLastVisited } = useContext(GlobalContext)

  const [ errors, setErrors ] = useState();  
  const [passwordVisible, setPasswordVisible] = useState(false);
  const TogglePasswordVisible = () => {setPasswordVisible(!passwordVisible)}
  const [userLogin, setUserLogin] = useState();

  useEffect(() => {
    let mounted = true;
    if(context.user){
      // history(RoutesNames.Cttavr.path, { state: { source: 'sign up' } });
      if(next){
        history(next, { state: { source: 'sign up' } } )
      }else {
        history(RoutesNames.Cttavr.path, { state: { source: 'sign up' } } )
      }
    }
    return () => {mounted = false}
  }, [context.user])

  const [addUser, { data, loading, error }] = useMutation (REGISTER_USER);
  const [loginUser, {data:datalogin, loading:loadinglogin, error:errorlogin }] = useMutation (LOGIN_USER, {
    update(_, {data: { tokenAuth: userData}}){
      context.login(userData)
    },
  });

  const submitNewUser = (values) => {
    addUser({variables: {username:values.username, email:values.email,
    password1:values.password1, password2:values.password2}})
    setUserLogin({username:values.username, password:values.password1})
  }

  useEffect(() => {
    let mounted = true;
    if(data?.register?.success && userLogin){
      loginUser({variables: {username:userLogin.username, password:userLogin.password}})
      if(lastVisited==='/institution'){changeLastVisited(RoutesNames.Account.path)}
    }
    if(data?.register?.errors){
      var e = data.register.errors
      if(e.username){var errorH = e.username[0].message+': '+userLogin.username}
      if(e.email){var errorH = e.email[0].message}
      if(e.password2){var errorH = e.password2[0].message}
      if(errorH){setErrors(errorH)}
    }
    if(datalogin?.tokenAuth?.errors?.nonFieldErrors){
      var errorH = datalogin.tokenAuth.errors.nonFieldErrors[0].message
      setErrors(errorH)
    }
    return () => {mounted = false}
  }, [data])

  useEffect(() => {
    if(errors){
      notifyError(errors)
      setErrors()
    }
    return () => {}
  }, [errors])

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password1: '',
      password2: '',
    },
    validationSchema: SignUpFormSchema,
    onSubmit: (values, actions) => {
      // console.log("form ", values)
      submitNewUser(values)
    },
  });

  // console.log('signup', formik.errors)

  return (
    <main>
      <ToastContainer/>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={RoutesNames.Presentation.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to Home
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" 
          >
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Medical Intelligence</h3>
                </div>

                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>

            <GoogleLogin
              onSuccess={credentialResponse => {
                console.log(credentialResponse);
                var decoded = jwt_decode(credentialResponse?.credential);
                var pass = Math.random().toString(36).slice(-8);
                var vals = {
                  username: decoded?.given_name + decoded?.family_name,
                  email: decoded?.email, password1: pass, password2: pass,
                }
                submitNewUser(vals)
              }}
              onError={() => {
                console.log('Login Failed');
                setErrors('Login Failed! Please try again using the form below!')
              }}
              useOneTap
            />

            <div className="text-center text-md-center mt-4">
              <h3 className="mb-0">Or</h3>
            </div>

            <Form className="mt-4" onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-4">
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSignInAlt} />
                  </InputGroup.Text>
                  <Form.Control autoFocus required type="text"
                  placeholder="Username" id="username"
                     {...formik.getFieldProps('username')}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-4">
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                  <Form.Control autoFocus required type="email"
                  placeholder="Email" id="email"
                     {...formik.getFieldProps('email')}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.Group className="mb-4">
                  <InputGroup>
                    <InputGroup.Text >
                      <span style={{  padding:5, cursor:'pointer', }} 
                      onClick={TogglePasswordVisible}>
                    <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                    {passwordVisible ? <AiFillEyeInvisible/> : <AiFillEye/>}
                    </IconContext.Provider>
                    </span>
                    </InputGroup.Text>
                    <Form.Control required type={passwordVisible ? 'text' : 'password'} placeholder="Password" id="password1"
                      {...formik.getFieldProps('password1')}
                    />
                  </InputGroup>
                </Form.Group>
                {formik.touched.password1 && formik.errors.password1 ? (
                  <p style={{ color:'red', textAlign:'center', }}>{formik.errors.password1}</p>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Group className="mb-4">
                  <InputGroup>
                    <InputGroup.Text >
                      <span style={{  padding:5, cursor:'pointer', }} 
                      onClick={TogglePasswordVisible}>
                    <IconContext.Provider value={{ color:'black', size:ICON_SIZE}}>
                    {passwordVisible ? <AiFillEyeInvisible/> : <AiFillEye/>}
                    </IconContext.Provider>
                    </span>
                    </InputGroup.Text>
                    <Form.Control required type={passwordVisible ? 'text' : 'password'} placeholder="Confirm password" id="password2"
                      {...formik.getFieldProps('password2')}
                    />
                  </InputGroup>
                </Form.Group>
                {formik.touched.password2 && formik.errors.password2 ? (
                  <p style={{ color:'red', textAlign:'center', }}>{formik.errors.password2}</p>
                ) : null}
              </Form.Group>

              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                <Button disabled={loading || loadinglogin ? true : false } 
                color="primary" variant="contained"
                type="submit"
                 > 
                  {loading || loadinglogin ? <Sentry /> : "Sign Up"}
                </Button>
              </Box>

            </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                  </span>
                </div>

                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                  <Button
                  color="primary" variant="contained"
                  type="submit"
                  // onClick={() => { history(RoutesNames.Signin.path) } } 
                  onClick={() => {
                        if(next){
                          history(RoutesNames.Signin.path + '?next=' + next) 
                        } else {
                          history(RoutesNames.Signin.path)
                        }
                      }
                    }
                  > 
                    Log In
                  </Button>
                </Box>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
