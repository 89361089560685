
import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { View, Text, useWindowDimensions, } from 'react-native'
import {  useMutation } from '@apollo/client';
import { useLocation, useNavigate } from "react-router-dom";

import Modal from 'react-modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Box, Button } from '@mui/material';

import { Sidebar } from "../../components/Sidebar";
import Tracker from "../../components/tracker";

import { UPLOAD_CTTAVR, } from '../../network/stroke';
import { ICON_SIZE, } from "../../styles/global";
import { AuthContext } from "../../config/auth";
import { ImagesToPhotos, millisToMinutesAndSecondsFormated, modalStylesSmall, notifConfig, } from "../../components/helpers";

import { PdfDownloadButtonCt } from "../../components/pdfitavr";
import { RoutesNames } from "../../routes";
import { HelpButton } from "../../components/elements";
import { FooterCustom } from "./FooterCustom";

import {useDropzone} from 'react-dropzone'
import { FaExclamationTriangle } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Grid } from "@mui/material";

// import { toPng } from 'html-to-image';
import { Image,  } from '@themesberg/react-bootstrap';

import { AiOutlineCloseCircle, AiOutlineCloudUpload } from "react-icons/ai";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Lottie from "lottie-react";
import animationClock from '../../lotties/clock_animation.json';
import animationFile1 from '../../lotties/file_animation1.json';
import animationSuccess from '../../lotties/success_animation.json';

import { NavbarInternal } from "./NavbarInternal";
import { BarChart } from "../../components/Charts";
import { LinearProgressWithLabel } from "../../components/elements";
import stroke from '../../assets/img/illustrations/stroke1.jpg';
import pacemaker from '../../assets/img/illustrations/pacemaker.png';
import thvs2 from '../../assets/img/illustrations/thvs2.png';
import bleeding from '../../assets/img/illustrations/bleeding.png';
import { ExportModal } from "../modals/exportmodal";


const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});

export const Cttavr = () => {
  const { user, hasLaunched, SaveFirstTimeDone } = useContext(AuthContext)

  const location = useLocation();
  // console.log('cttavr', location);

  useEffect(()=>{
    if(!user){
      history(RoutesNames.Signin.path);
    }
  }, [user])

  const [showH, setShowH] = useState(false)

  useEffect(()=>{
    if(['sign in', 'sign up'].includes(location?.state?.source) && hasLaunched === null){
      setShowH(true)
    }
  }, [])
  const ToggleShow = () => {setShowH(!showH)}
  const [showExportModal, setShowExportModal] = useState(false)
  const ToggleShowExportModal = () => {setShowExportModal(!showExportModal)}

  const [photos, setPhotos] = useState([]);
  const [index, setIndex] = useState(-1);

  const WIDTH = useWindowDimensions().width

  let history = useNavigate();

  const [startTime, setStartTime] = useState(new Date())
  const [sinceStart, setSinceStart] = useState(new Date())
  const [timeShown, setTimeShown] = useState(0)
  const updateClock = (initTime) => {
    var now = new Date();
    var milli = now.getTime() - initTime;
    setSinceStart(milli)
  }

  const [progress, setProgress] = useState(0);
  const INCREMENT = 1

  useEffect(() => {
      const timer = setInterval(() => {
        setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + INCREMENT));
        updateClock(startTime);
      }, 1500); // 2.5 minutes
      return () => {
        clearInterval(timer);
      };
  }, [startTime]);

  const [errors, setErrors] = useState()
  const elementRef = useRef(null);

  const [uploadCttavrFile, {data:datacv, loading:loadingcv, error:errorcv}] = useMutation(UPLOAD_CTTAVR);

  const initialResult = []
  const [result, setResult] = useState(initialResult);
  const [message, setMessage] = useState()

  const [patientSelected, setPatientSelected] = useState('')

  const createFile = (bits, name, options) => {
    try {
        return new File(bits, name, options);
    } catch (e) {
        var myBlob = new Blob(bits, options || {});
        myBlob.lastModified = new Date();
        myBlob.name = name;
        return myBlob;
    }
  };

  const MyDropzone = () => {
    const onDrop = useCallback(files => {
      const file = files[0]

      if(!file?.name?.includes('.zip') && !file?.name?.includes('.7z') && !file?.name?.includes('.nii.gz')){
        const errMess = 'Your file does not appear to be compressed or zipped. Please compress or zip your folder (right click on the folder then Compress or Zip). Then upload your .zip or .7z file here!'
        setMessage(errMess)
        notifyError(errMess)
      }else{
        setMessage('')
        var file1 = createFile([file], '_'+file.name, {});
        uploadCttavrFile({ variables: { file:file1 } })
        setPatientSelected(file?.name)
        setResult([])
        setProgress(0)
        setStartTime(new Date())
        // setTimerActive(true)
      }
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  
    return (
      <div {...getRootProps()} 
        style={{ height:220, cursor:'pointer', borderRadius:10,
        backgroundColor:isDragActive ? '#f2f2f2' : 'white', 
        borderStyle: 'dashed',
        borderColor:'#1764c0',
         }}
        >
        <input {...getInputProps()}/>
        {
          isDragActive ?
            <p style={{ fontSize:18, height:14, lineHeight:14, textAlign:'center', fontWeight:'bold', }}>Drop the file ...</p> :
            <div>
                <p style={{ color:'black', height:5, lineHeight:5, textAlign:'center', fontWeight:'bold', }}>
                  <IconContext.Provider value={{ color: 'rgb(54, 162, 235)', size:130, }} >
                    <AiOutlineCloudUpload />
                  </IconContext.Provider>
                </p>
                <p style={{  color:'black', fontSize:18, height:12, lineHeight:12, textAlign:'center', fontWeight:'bold', }}>
                Drop the CT file (.zip)
                </p>
            </div>
        }
      </div>
    )
  }

  const ExampleCts = {
      'high_risk_stroke_1.nii.gz': 'High risk CT 1',
      'low_risk_stroke_1.zip': 'Low risk CT 1',
      'high_risk_stroke_2.nii.gz': 'High risk CT 2',
  }

  const FirstTime = () => {
    const [draggedItem, setDraggedItem] = useState(null);
    const [droppedItems, setDroppedItems] = useState([]);
  
    const handleDragStart = () => {
      setDraggedItem(null); // Reset the dragged item
    };
  
    const handleDrop = (item) => {
      setDroppedItems([...droppedItems, item]);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
    };
  
    const DraggableItem = ({ item, onDragStart }) => {
      // console.log('DraggableItem item', item)
      const handleDragStart = (e) => {
        e.dataTransfer.setData('text/plain', item); // Set the data being dragged
        onDragStart();
      };
    
      return (
        <div
          draggable
          onDragStart={handleDragStart}
          style={{ cursor:'pointer', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', }}
          onClick={() => {
            var file1 = createFile(['file'], '_'+item, {});
            uploadCttavrFile({ variables: { file:file1 } })
            setPatientSelected(item)
            setResult([])
            setProgress(0)
            setStartTime(new Date())
          }}
        >
          <Lottie animationData={animationFile1} 
          style={{ 
            height:110, width:110,
            }} /> 
          <p style={{ fontSize:12, paddingTop:0, marginTop:0, }}>{ExampleCts[item]}</p>
        </div>
      );
    };
  
    const DroppableArea = ({ onDrop, onDragOver }) => {
      const handleDrop = (e) => {
        e.preventDefault();
        const data = e.dataTransfer.getData('text/plain'); // Get the data being dropped
        onDrop(data);
        var file1 = createFile(['file'], '_'+data, {});
        uploadCttavrFile({ variables: { file:file1 } })
        setPatientSelected(data)
        setResult([])
        setProgress(0)
        setStartTime(new Date())

      };
    
      return (
        <div
          onDrop={handleDrop}
          onDragOver={onDragOver}
          onClick={handleDrop}
        >
        <div 
          style={{ height:200, borderRadius:10,
          backgroundColor: 'white', 
          borderStyle: 'dashed',
          borderColor:'#1764c0',
           }}
          >
            <div>
                <p style={{  color:'black', height:5, lineHeight:5, textAlign:'center', fontWeight:'bold', }}>
                  <IconContext.Provider value={{ color: 'rgb(54, 162, 235)', size:130, }} >
                    <AiOutlineCloudUpload />
                  </IconContext.Provider>
                </p>
                <p style={{  color:'black', fontSize:18, height:12, lineHeight:12, textAlign:'center', fontWeight:'bold', }}>
                Drop the CT file
                </p>
            </div>
        </div>
          
        </div>
      );
    };

    return (
        <View>
        <Modal
          isOpen={showH}
          onRequestClose={ToggleShow}
          style={modalStylesSmall}
          contentLabel="Description"
          >
          <View style={{ flex:1, justifyContent:'space-between', }}>
              <View style={{ position:'absolute', top:-25, right:-20, zIndex:1000, 
                  backgroundColor:'transparent', }}>
                  <span style={{ padding:10, cursor:'pointer', alignItems:'center', justifyContent:'center', }} 
                  onClick={() => {
                    ToggleShow();
                    SaveFirstTimeDone();
                  }}
                  >
                  <IconContext.Provider value={{ color: 'black', size:ICON_SIZE, }} >
                      <AiOutlineCloseCircle />
                  </IconContext.Provider>
                  </span>
              </View>
              <h4 style={{ textAlign:'center', color:'black', marginTop:10, marginBottom:10, }}>First time using AI TAVR?</h4>

              {!loadingcv && timeShown > 0 ? 
              <div style={{ margin:10, }}>
                <View style={{ flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center',  }}>
                  <Lottie animationData={animationSuccess}  style={{ height:120, width:120, }} loop={1} /> 
                </View>

                <h6 style={{ textAlign:'center', color:'black', marginTop:10, marginBottom:20, }}>The report has been generated in <span style={{ fontSize:18, fontWeight:'bold', }}>{millisToMinutesAndSecondsFormated(timeShown)}</span>!</h6>
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 0 }} >
                  <Button
                  color="primary" variant="contained"
                  type="submit"
                  onClick={() => {
                    ToggleShow();
                    }}
                  > 
                    See the report
                  </Button>
                </Box>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                  <Button
                  color="primary" variant="contained"
                  type="submit"
                  onClick={() => history(RoutesNames.Bookdemo.path)}
                  > 
                      Contact our team
                  </Button>
                </Box> */}
              </div>
              : null}

              {!loadingcv && timeShown === 0 ? <h5 style={{ textAlign:'center', color:'black', }}>Drop this CT file in the drop zone:</h5> : null}
              {/* {!loadingcv && timeShown === 0 ?  */}
              {!loadingcv ? 
              <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', }}>
              <DraggableItem item="high_risk_stroke_1.nii.gz" onDragStart={handleDragStart} />
              <DraggableItem item="low_risk_stroke_1.zip" onDragStart={handleDragStart} />
              <DraggableItem item="high_risk_stroke_2.nii.gz" onDragStart={handleDragStart} />
              </div>
              : null}
              {/* {!loadingcv && timeShown === 0 ? <DroppableArea onDrop={handleDrop} onDragOver={handleDragOver} /> : null} */}
              {!loadingcv ? <DroppableArea onDrop={handleDrop} onDragOver={handleDragOver} /> : null}

              {loadingcv ? 
              <div>
                <View style={{ flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center',  }}>
                  <Lottie animationData={animationClock}  style={{ height:250, width:250, }} /> 
                </View>
                <View style={{ flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:10,  }}>
                  <Text> Analyzing {patientSelected} ...</Text>
                </View>
                <View style={{ flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:10,  }}>
                  <Text>{millisToMinutesAndSecondsFormated(sinceStart)}</Text>
                </View>
              </div>
              : null
              }
              
              <br/>
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
                <Button
                color="primary" variant="contained"
                type="submit"
                onClick={() => {
                  ToggleShow();
                  ToggleShowExportModal();
                } }
                > 
                    How to Export-Anonymize a CT
                </Button>
              </Box>

          </View>
        </Modal>
        </View>
        );
  }


  useEffect(()=>{
    if(datacv?.uploadCttavr){
      setProgress(100)
      setTimeShown(sinceStart)
      var result = JSON.parse(datacv?.uploadCttavr?.result)
      setResult(result?.result)
      var photos = result?.images?.length>0? ImagesToPhotos(result?.images) : []
      setPhotos(photos)
      var mess = datacv?.uploadCttavr?.result?.message
      setMessage(mess ? mess : '')
      if(mess?.length>0){
        notifyInfo(mess)
      }
    }
  }, [datacv])

  // console.log('cttavr', result, photos, message)

  useEffect(()=>{
    if(errorcv){
      setProgress(100)
      console.log('errorcv', errorcv)
      if(errorcv?.message?.length>0){
        setErrors(errorcv?.message)
      }
    }
  }, [errorcv])

  useEffect(()=>{
    if(errors?.length>0){
      if(errors.includes('fetch')){
        notifyError('Error connecting to the server. Please try again!')
      } else if(errors.includes('token')) {
        notifyError('Error connecting to the server. Please check your Internet connection and try again!')
      } else if(errors.includes('processing')) {
        notifyError(errors)
      } else {
        notifyError('Sorry, an error occurred. Please try again!')
      }
      setErrors('')
      setPatientSelected('')
    }
  }, [errors])

  // console.log('ct result', result)

  const RiskControl = {
    'Stroke risk': 2.0, 'Pacemaker risk': 2.0, 'Mortality risk': 1.0,
    'Cardiac technical failure risk': 1.0, 'Vascular technical failure risk': 5.0,
  }


  return (
    <View>
      <Sidebar/>
      <ToastContainer/>
      <Tracker />

      <main className="content p-0 m-0">
      <NavbarInternal />

      <View style={{ padding:10, }}>

      <FirstTime />

      <div className="justify-content-center" style={{ padding:20, }}>
        <h2 className="text-center">AI TAVR: tailor your TAVR plan to the patient anatomy.</h2>
      </div>

      <div className="justify-content-center" style={{ paddingBottom:20, }}>
        <h5 className="text-center" style={{ color:'#5f6369', }}>Get a personalized risk assessment for your case.</h5>
      </div>

      <br/>

      {!loadingcv ? 
      <div className="justify-content-center" 
      style={{ borderRadius:10, }}
      >
        <MyDropzone />
      </div>
      : null}


      {loadingcv ? 
      <div style={{ marginTop:40, padding:10, }}>
        <br/>
        <LinearProgressWithLabel value={progress} />

        <View style={{ flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center',  }}>
          <Lottie animationData={animationClock}  style={{ height:250, width:250, }} /> 
        </View>

        <View style={{ flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:10,  }}>
          <Text> Analyzing {patientSelected} ...</Text>
        </View>

        <View style={{ flex:1, flexDirection:'row', justifyContent:'center', alignItems:'center', marginTop:10,  }}>
          <Text> {millisToMinutesAndSecondsFormated(sinceStart)}</Text>
        </View>

        <br/>
      </div>
      : null}

      {message?.length>0 && result?.length<2 ?
        <View style={{ flex:1, justifyContent:'center', alignItems:'center', marginTop:30, marginBottom:30, }}>
          <IconContext.Provider value={{ color: '#ff6666', size:40, }} >
            <FaExclamationTriangle/>
          </IconContext.Provider>
          <Text style={[ {fontSize:20, padding:5, textAlign:'center', } ]}>{message}</Text>
        </View>
      : null}

      {(()=>{
        if(result?.length>0 && !loadingcv){
          return (
          <View style={{ marginLeft:5, marginTop:20, borderRadius:15, justifyContent:'center', }} >
            <br/>

            <View ref={elementRef}>
            <div className="d-flex align-items-center justify-content-center p-1">
              <Text style={[{ fontSize:24, textAlign:'center', } ]}>
              TAVR risk table for <span style={{ fontSize:24, fontWeight:'bold', }}>{patientSelected}</span>
              </Text>
              <HelpButton label={"iTAVR Outcomes"} />
            </div>
            <div className="d-flex align-items-center justify-content-center p-1">
              <Text style={[{ fontSize:24, textAlign:'center', }  ]}>
                Generated in <span style={{ fontSize:24, fontWeight:'bold', }}>{millisToMinutesAndSecondsFormated(timeShown)}</span>!
              </Text>
            </div>

            <Grid container spacing={1}>
            {(()=>{
              return result.map((item, key) => {

                var ls = item[1]
                var labels = ['Case']
                var ns = ls.map((i, k) => i.filter(v => v.includes('%')) )
                var risks = ns.map((i, k) => Number(i[0].replace('%', '')) )

                return (
                  <Grid item lg={6} xs={12}>

                  <View>
                    <Text key={key} style={[{ fontSize:24, marginTop:5, marginBottom:10, alignSelf:'center', }]}>
                    {item[0]}
                    </Text>

                    <BarChart title={`${item[0]}`} labels={labels} datas={risks} control={RiskControl[item[0]]} />

                    <br/>
                    <br/>
                  </View>

                  </Grid>
                )
              }
                )
            })()}
            </Grid>

            </View>

            {result?.includes('*') ?
              <div>
                <Text style={[{ paddingVertical:30, }]}>(*) Mortality: the 30-day rate in medically treated patients in the PARTNER 1B trial was 2.8%.</Text>
              </div>
            : null}

            <br/>

            <PhotoAlbum photos={photos} layout="rows" targetRowHeight={250} onClick={({ index }) => setIndex(index)} />
            <Lightbox
              slides={photos}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              plugins={[Fullscreen, Zoom]}
            />

            <br/>

            <View style={{ flexDirection:'row', justifyContent:'center', marginTop:5, }}>
              <Box sx={{ display: 'flex', justifyContent: 'center', }} >
                <PdfDownloadButtonCt result={result} patientSelected={patientSelected} />
              </Box>
            </View>

          </View>
            )
            }

        })()}

      <br/>
      <br/>
      <p style={{ fontWeight:'bold', color:'black', }}>Stroke risk</p>
      <Grid container spacing={1}>
        <Grid item lg={2} xs={12}>
        <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
          src={stroke} alt="Stroke"
          style={{ 
            maxWidth:250,
            width:'100%',
            marginBottom:10,
            }} 
          />
        </Grid>

        <Grid item lg={10} xs={12}>
          <div style={{ marginLeft:WIDTH < 800 ? 0 : 10, }}>
          <p style={{ color:'black', }}>Stroke occurs in between 2.5 and 3.5% of cases in most centers.</p>
          <p style={{ color:'black', }}>Contributing risk factors could be the severity of the aortic valve disease, aortic calcifications and thrombi on and around the transcatheter heart valve (THV).</p>
          <p style={{ color:'black', }}>Preventive techniques include the optimization of the THV choice, the optimization of anesthetic conditions, avoidance of pre-dilatation and post-dilatation, cerebral protection and careful THV delivery.</p>
          </div>
        </Grid>
      </Grid>

      <br/>
      <br/>
      <p style={{ fontWeight:'bold', color:'black', }}>Pacemaker risk</p>
      <Grid container spacing={1}>
        <Grid item lg={2} xs={12}>
        <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
          src={pacemaker} alt="Pacemaker"
          style={{ 
            maxWidth:250,
            width:'100%',
            marginBottom:10,
            }} 
          />
        </Grid>

        <Grid item lg={10} xs={12}>
          <div style={{ marginLeft:WIDTH < 800 ? 0 : 10, }}>
          <p style={{ color:'black', }}>Pacemaker is required between 10 and 20% of cases.</p>
          <p style={{ color:'black', }}>Contributing risk factors could be the presence of a right bundle branch block, the low position of the THV.</p>
          <p style={{ color:'black', }}>Preventive techniques include higher positioning of the THV and the pre-TAVR pacemaker placement.</p>
          </div>
        </Grid>
      </Grid>

      <br/>
      <br/>
      <p style={{ fontWeight:'bold', color:'black', }}>Mortality risk</p>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div style={{ marginLeft: 0, }}>
          <p style={{ color:'black', }}>Mortality occurs between 0.5 and 1% of cases.</p>
          <p style={{ color:'black', }}>Patient frailty and the presence of major comorbidities increase the risk.</p>
          <p style={{ color:'black', }}>In high risk patients, surgery or medical treatment might be a better alternative.</p>
          </div>
        </Grid>
      </Grid>

      <br/>
      <br/>
      <p style={{ fontWeight:'bold', color:'black', }}>Cardiac technical failure risk</p>
      <Grid container spacing={1}>
        <Grid item lg={2} xs={12}>
        <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
          src={thvs2} alt="2 THVs"
          style={{ 
            maxWidth:250,
            width:'100%',
            marginBottom:10,
            }} 
          />
        </Grid>

        <Grid item lg={10} xs={12}>
          <div style={{ marginLeft:WIDTH < 800 ? 0 : 10, }}>
          <p style={{ color:'black', }}>It is an unsuccessful or incorrect delivery of the valve or cardiac structural complication, possibly requiring surgery or intervention related to the device.</p>
          <p style={{ color:'black', }}>It occurs between 2 and 3% of cases.</p>
          <p style={{ color:'black', }}>Preventive techniques include a better THV choice and careful THV delivery. In high risk patients, surgery or medical treatment might be a better alternative.</p>
          </div>
        </Grid>
      </Grid>

      <br/>
      <br/>
      <p style={{ fontWeight:'bold', color:'black', }}>Vascular technical failure risk</p>
      <Grid container spacing={1}>
        <Grid item lg={2} xs={12}>
        <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
          src={bleeding} alt="Bleeding"
          style={{ 
            maxWidth:250,
            width:'100%',
            marginBottom:10,
            }} 
          />
        </Grid>

        <Grid item lg={10} xs={12}>
          <div style={{ marginLeft:WIDTH < 800 ? 0 : 10, }}>
          <p style={{ color:'black', }}>It is an unsuccessful or incorrect delivery of the valve or vascular complication, possibly requiring vascular surgery or intervention.</p>
          <p style={{ color:'black', }}>It occurs between 8 and 12% of cases.</p>
          <p style={{ color:'black', }}>Preventive techniques include a better choice of TAVR access, surgery, imaging-guiding access or surgery.</p>
          </div>
        </Grid>
      </Grid>

      <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
          <Button
          color="primary" variant="contained"
          type="submit"
          onClick={() => {
            ToggleShow();
            }}
          > 
              Open demo
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
          <Button
          color="primary" variant="contained"
          type="submit"
          onClick={() => {
            ToggleShowExportModal();
          } }
          > 
              How to Export-Anonymize a CT
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
          <Button
          color="primary" variant="contained"
          type="submit"
          onClick={() => history(RoutesNames.Bookdemo.path)}
          > 
              Contact our team
          </Button>
        </Box>
      </div>

      <ExportModal
      show={showExportModal} 
      />


      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>


      <br/>
      <br/>
      <br/>
      <br/>

      <br/>
      <br/>
      <br/>
      <br/>

      {/* <div style={{ backgroundImage: `url(${ct_chest})`, padding:40, height:150, }}></div> */}

    </View>

    <FooterCustom />

    </main>

    </View>
  );
};
