import * as React from 'react';

import { useNavigate } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Button, } from '@mui/material';

import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import MenuItem from '@mui/material/MenuItem';

import { HashLink } from 'react-router-hash-link';
import Nav from 'react-bootstrap/Nav';
import { Image } from '@themesberg/react-bootstrap';
import { RoutesNames } from '../../routes';
import logoImage from '../../assets/img/brand/logo_mai_noback.png';
import { AuthContext } from '../../config/auth';


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const ProductMenu = () => {
  let history = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="default"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="large"
        style={{ textTransform:'none', fontSize:18, color:'black', marginTop:2, }}
      >
        Products
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem 
        onClick={() => history(RoutesNames.AITAVR.path)}
        >
          AI TAVR
        </MenuItem>
        <MenuItem
        onClick={() => history(RoutesNames.AIPE.path)}
        >
          AI PE
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

const pages = ['Team', 'Log in', 'Contact us', ];
const hashLinks = {'Team': RoutesNames.Team.path, 'Log in': RoutesNames.Signin.path, 'Contact us': RoutesNames.Bookdemo.path,};

function ResponsiveAppBar() {
  const { user, } = React.useContext(AuthContext)
  let history = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };


  return (
    <AppBar position="static" color='transparent' elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className='justify-content-space-between'>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          <Nav.Link eventKey={1} as={HashLink} to={RoutesNames.Presentation.path} 
            style={{ fontSize:18, position:'relative', }}>
            <Image style={{ maxWidth:300 }} src={logoImage} />
          </Nav.Link>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >

            <MenuItem key={0} onClick={handleCloseNavMenu}>
              <Nav.Link eventKey={1} as={HashLink} to={RoutesNames.Presentation.path} 
                style={{ fontSize:18 }}>
                <Typography  textAlign="center">Home</Typography>
              </Nav.Link>
            </MenuItem>

              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Nav.Link eventKey={1} as={HashLink} to={hashLinks[page]} 
                    style={{ fontSize:18 }}>
                    <Typography textAlign="center" style={{ color:'black', }}>{page}</Typography>
                  </Nav.Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'flex-end', marginRight:20 } }}>
            {pages.map((page) => (
              <div className='p-2'>
              <Nav.Link eventKey={1} as={HashLink} to={hashLinks[page]} 
                style={{ fontSize:18, color:'black', }}>
                {page}
              </Nav.Link>
              </div>
            ))}
          </Box>
        
          <div style={{ display:'flex', flexDirection:'row', }}>
            <Box sx={{ flexGrow: 1, }} >
              <ProductMenu />
            </Box>

            {user ? <Button onClick={() => history(RoutesNames.Cttavr.path)} 
                variant="contained" 
                size="large"
                style={{ textTransform:'none', fontSize:18, }}>
                  App
              </Button>
              : <Button onClick={() => history(RoutesNames.Signup.path)} 
                variant="contained" 
                size="large"
                style={{ textTransform:'none', fontSize:18, }}>
                  Demo
              </Button>
            }

          </div>

        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;