import {StyleSheet, } from 'react-native';

// change to true for PROD
export const LOCAL = false

export const DOMAIN = LOCAL ? 'http://127.0.0.1:8000/' : 'https://www.medniscientai.com/'
export const LINK_MEDIA = LOCAL ? 'http://127.0.0.1:9000/media/' : 'https://www.medniscientai.com/static/media/'

export const ICON_SIZE = 23

export const LanguageDict = {
  'fr': 'French',
  'en': 'English',
  'de': 'German',
  'es': 'Spanish',
  'zh': 'Chinese',
  'ca': 'Canadian',
}

export const FONT_SIZE = 15

export const globalStyles = StyleSheet.create ({
    textNormal: {
      fontSize: FONT_SIZE,
    },
    errorText: {
      color: 'crimson',
      textAlign: 'center',
      fontFamily:'typeface-roboto', 
    },
    textDescription: {
      fontFamily:'typeface-roboto', 
      // textAlign:'center', 
      fontSize:FONT_SIZE,
      paddingBottom:4,
    },
    textTitle:{
      fontFamily:'typeface-roboto', 
      // textAlign:'center', 
      fontSize:FONT_SIZE+2,
      // paddingBottom:5,
    }
  })

