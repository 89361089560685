import React, { useState, useEffect, useContext, } from "react";
import { useWindowDimensions } from "react-native";
import { Box, Button } from '@mui/material';
import { useNavigate } from "react-router";
import { RoutesNames } from "../routes";

export const InstitutionalSignUpButtonTour = () => {
  let history = useNavigate()
  return (
  <Box sx={{ display: 'flex', justifyContent: 'center', }} >
    <Button color="primary" variant="contained" onClick={()=>{
        history(RoutesNames.SignupInstitution.path)
      }} > Institutional sign up
    </Button>
  </Box>
  )
}

export const SignUpButtonTour = () => {
  let history = useNavigate()
  return (
  <Box sx={{ display: 'flex', justifyContent: 'center', }} >
    <Button color="primary" variant="contained" onClick={()=>{
        history(RoutesNames.Signup.path)
      }} > Sign up
    </Button>
  </Box>
  )
}

export const SignInButtonTour = () => {
  let history = useNavigate()
  return (
  <Box sx={{ display: 'flex', justifyContent: 'center', }} >
    <Button color="primary" variant="contained" onClick={()=>{
        history(RoutesNames.Signin.path)
      }} > Sign in
    </Button>
  </Box>
  )
}

export const steps = [
    { selector: '.tour-step-1', content: 'Welcome to the Cerebrovascular Event Risk Calculator (CVERC) application!' },
    { selector: '.tour-step-2', content: 'Please login to get access to all the features' },
    { selector: '.tour-step-3', content: () =>
      <div>
          <p>An account or access key is required to get full access to the calculator.</p>
          <p>Are you an institution?</p>
          <InstitutionalSignUpButtonTour/>
          <p className="pt-1">Already have an account?</p>
          <SignInButtonTour />
          <p className="pt-1">You are an expert and wish to join the Expert Explorer for free?</p>
          <SignUpButtonTour />
      </div>
      },
  ]

export const sizes = {
    bottom: 0,
    left: 0,
  }



export function calcPosition(sizes){
  const { windowWidth, width, windowHeight, height } = sizes
  return [windowWidth - width - 0, windowHeight - height - 20]
  }
