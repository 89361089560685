import React, { useState, useEffect, } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCheckCircle, faFolder,   } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Card, Image, 
  // Button, 
  Container, } from '@themesberg/react-bootstrap';
import { TwitterFollowButton, } from 'react-twitter-embed';
import { LinkedinFollowCompany } from 'react-social-plugins';
import {  Button } from '@mui/material';

import { RoutesNames } from "../routes";
import { FooterCustom } from "./dashboard/FooterCustom";
import ResponsiveAppBar from "./dashboard/ResponsiveAppBar";
import happy from '../assets/img/brand/happy_old_man1.jpg';
import happy1 from '../assets/img/brand/happy_old_woman1.jpg';

export const Presentation = () => {
  let history = useNavigate();

  return (
    <>
      <ResponsiveAppBar/>

      <section className="section">
        <Container>
            <Row className="justify-content-between align-items-center">
              <Col lg={5} className="order-lg-1 text-center">
                <br/>
                <br/>
                <h2 className="mb-1 fw-bold" style={{ color:'black', }}>Empowering Tomorrow’s Health, Today.</h2>

                <br/>
                <h4 className="mb-2" style={{ color:'black', }}>Medniscient AI is a digital healthcare company revolutionizing the way clinicians approach interventional and surgical treatment.</h4>
                <br/>
                <p className="mb-2" style={{ color:'black', }}>Our clinically-proven AI digital healthcare platform works with cardiovascular computed tomography angiography to help clinicians identify prognostic patterns, so they can provide personalized, life-saving treatment plans for patients requiring an intervention.</p>

                <br/>
                <Button onClick={() => history(RoutesNames.Bookdemo.path)} 
                variant="contained" 
                style={{ borderRadius:30, textTransform:'none', }}>
                Book a free demo
                </Button>
                <br/>
                <br/>
              </Col>
              <Col lg={6} className="order-lg-2 p-1 mt-2">
                <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
                  // src="https://www.alviss.ai/media/images/woman_gazing.jpg" 
                  src={happy1}
                  alt="TAVR"
                  style={{ 
                    maxWidth:500,
                    width:'100%',
                    borderTopLeftRadius:40,
                   }} 
                  />
              </Col>
            </Row>
        </Container>
      </section>


    <section className="section">
      <Container>
      <Row className="justify-content-between align-items-center pb-2">
            <h2 className="text-center" style={{ color:'black', }}>AI TAVR is an artificial intelligence that reads CT scans to predict the risk of surgical complications, like stroke.</h2>
      </Row>
      </Container>
    </section>

      <section className="section">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={5} className="order-lg-1">
              <h4>Why using AI TAVR</h4>
              <br/>
              <p className="mb-1"><strong>Stroke</strong> occurs in approximately <strong>3% of transcatheter aortic valve replacement (TAVR) procedures</strong>.</p>
              <p className="mb-1">Even experienced operators cannot predict stroke.</p>
              <p className="mb-1"><strong>AI TAVR uses deep learning and computer vision to predict 9 out of 10 strokes</strong> related to TAVR.</p>
              <p className="mb-1">Predicting stroke during procedure planning could allow tailoring procedures to reduce the risk of stroke.</p>
              <p className="mb-1">AI TAVR also predicts other surgical risks.</p>
              <br/>
              <Button onClick={() => history(RoutesNames.Bookdemo.path)} 
                variant="contained" 
                style={{ borderRadius:30, textTransform:'none', }}>
                Book a free demo
              </Button>
              <br/>
              <br/>
            </Col>
            <Col lg={6} className="order-lg-2 text-center p-1 mt-2">
              <br/>
              <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
              style={{ maxWidth:500,
                borderBottomLeftRadius:40,
               }} 
              // src="https://www.alviss.ai/static/images/happy_old_man.png"
              src={happy} alt="AI TAVR patient"            
              />
                <br/>
            </Col>
          </Row>
        </Container>
      </section>

      <section style={{ backgroundColor:'#1764c0', }}>
        <br/>
        <br/>
        <Container>
        {/* <Card border="light" className="shadow p-4"> */}
          <Row className="justify-content-between align-items-center pb-2">
            <h4 className="text-center" style={{ color:'white', }}>Connect your institution to our cloud based predictive medicine system</h4>
            <br/>
            <br/>
            <Col lg={12}>
              <p className="mb-3 mt-2 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                AI TAVR doesn't need EMR integration. It is an end to end AI that reads CT scan images.
              </p>
              <p className="mb-3 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                AI TAVR has an API-ready system.
              </p>
              <p className="mb-3 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                AI TAVR has a ready-to-use Web application.
              </p>
              <p className="mb-3 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                AI TAVR can be easily integrated into your PACS or CT processing software.
              </p>
              <p className="mb-1 text-white">AI TAVR is the first predictive medicine solution for heart surgery and interventional cardiology.</p>
              <p className="mb-1 text-white">AI TAVR is geared towards value-based care.</p>
              <p className="mb-1 text-white">Already 10+ hospitals are using AI TAVR.</p>
            </Col>
          </Row>
        </Container>
        <br/>
        <br/>
      </section>

      <section className="section">
        <Container>
          <Row className="justify-content-between align-items-center">
            <h4 className="text-center p-2" style={{ color:'black', }}>Our science</h4>
            <Col lg={12}>
              <p className="mb-1"><strong>"Deep learning-based prediction of early cerebrovascular events after transcatheter aortic valve replacement."</strong></p>
              <p className="mb-1">More than 1 million data points were used to train the AI TAVR deep learning predictive model.</p>
              <br/>
              <Button onClick={() => window.location.href = 'https://www.nature.com/articles/s41598-021-98265-5'} 
                variant="contained" 
                style={{ borderRadius:30, textTransform:'none', }}>
                Read the article
              </Button>
            </Col>
          </Row>
        </Container>
      </section>


      <section className="section">
        <Container>
          <Row className="text-center justify-content-center mb-1">
            <Col lg="12">
              <h4 className="text-center" style={{ color:'black', }}>They talk about us</h4>
            </Col>
          </Row>
          <br/>
          <Row className="row-grid justify-content-center text-center">
          <Col lg="3">
            <a href='https://outsellinc.com/emerging-50'
              target="_blank" rel='noreferrer'
              >
              <img
              alt="Outsell 50 logo"
              className="img-center img-fluid shadow shadow-lg--hover"
              src={"https://miro.medium.com/v2/resize:fit:828/format:webp/1*fDtGNxExkjxzGIyhTWnmlA.png"}
              style={{ width:200, margin:10, }}
              />
            </a>
            </Col>

            <Col lg="3">
            <a href='https://medcitynews.com/2022/10/a-predictive-analytics-tool-for-strokes'
              target="_blank" rel='noreferrer'
              >
              <img
              alt="MedCity News logo"
              className="img-center img-fluid shadow shadow-lg--hover"
              src={"https://cdn2.hubspot.net/hubfs/5279328/logo-3.png"}
              style={{ width:200, margin:10, }}
              />
            </a>
            </Col>
            <Col lg="3">
            <a href='https://ces.tech/Media/New-Product-Launches.aspx'
              target="_blank" rel='noreferrer'
              >
              <img
              alt="CES Logo"
              className="img-center img-fluid shadow shadow-lg--hover"
              src={"https://logowik.com/content/uploads/images/ces-consumer-technology-association5836.jpg"}
              style={{ width:200, margin:10, }}
              />
            </a>
            </Col>
            <Col lg="3">
            <a href='https://www.nature.com/articles/s41598-021-98265-5'
              target="_blank" rel='noreferrer'
              >
              <img
              alt="Nature"
              className="img-center img-fluid shadow shadow-lg--hover"
              src={"https://cdn-images-1.medium.com/max/1200/0*MF0JeaQpsxMrhpBc.png"}
              style={{ width:200, margin:10, }}
              />
            </a>
            </Col>
          </Row>
        </Container>
      </section>


      <section className="section">
      <Container>
        <Row className="text-center justify-content-center pb-2">
          <Col lg="12">
            <h4 className="text-center" style={{ color:'black', }}>Partners</h4>
            <br/>
          </Col>
        </Row>
        <Row className="row-grid justify-content-center text-center">
          {/* <Col lg="3">
          <a href='https://www.crowncommercial.gov.uk/'
            target="_blank" rel='noreferrer'>
            <img
            alt="G-cloud"
            className="img-center img-fluid shadow shadow-lg--hover"
            src={"https://www.alviss.ai/media/images/brand/govuk.png"}
            style={{ width:200, margin:10, }}
            />
          </a>
          </Col> */}
          <Col lg="3">
          <a href='https://www.healthwildcatters.com'
            target="_blank" rel='noreferrer'>
            <img
            alt="Health Wildcatters"
            className="img-center img-fluid shadow shadow-lg--hover"
            src={"https://images.squarespace-cdn.com/content/v1/63e15579a08795186bedfb3e/fca393b9-aa3d-4f29-a34a-5818aa6e4ec9/HW+Logo+Horizontal.png?format=1500w"}
            style={{ width:200, margin:10, }}
            />
          </a>
          </Col>
          <Col lg="3">
          <a href='https://www.nvidia.com/en-us/startups/'
            target="_blank" rel='noreferrer'>
            <img
            alt="NVIDIA"
            className="img-center img-fluid shadow shadow-lg--hover"
            src={"https://www.nvidia.com/content/dam/en-zz/Solutions/about-nvidia/logo-and-brand/01-nvidia-logo-vert-500x200-2c50-d@2x.png"}
            style={{ width:200, margin:10, }}
            />
          </a>
          </Col>
          <Col lg="3">
          <a href='https://aws.amazon.com/'
            target="_blank" rel='noreferrer'>
            <img
            alt="AWS"
            className="img-center img-fluid shadow shadow-lg--hover"
            src={"https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg"}
            style={{ width:200, margin:10, }}
            />
          </a>
          </Col>
        </Row>

      </Container>
      </section>

    <section className="section">
      <Container>
      <Row className="text-center justify-content-center">
      <div class="sketchfab-embed-wrapper"> <iframe style={{ width:'100%', height: 400, }} title="Heart" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking execution-while-out-of-viewport execution-while-not-rendered web-share src="https://sketchfab.com/models/1f2d649198484ea2a76096bcd3ca1236/embed?autospin=1&autostart=1&ui_hint=0&dnt=1"> </iframe> </div>
      </Row>
      </Container>
    </section>

    <section className="section">
        <Container className="pb-2">
        <Row className="text-center justify-content-center">
            <Col lg="12">
              <h4 className="text-center pb-2">Reach out to our community</h4>
            </Col>
        </Row>
        <br/>
        <Row className="text-center justify-content-center pt-2 mt-2">
          <Col xs={12}>
            <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7053784345089597441" height="1000" width="100%" frameborder="0" allowfullscreen="" title="Embedded post"></iframe>
          </Col>
        </Row>
        
        <br/>
        <Row className="text-center justify-content-center">

        <Col xs={12} className="mt-2 mb-2">
          <LinkedinFollowCompany
            companyId={69185862}
            counter="top"
            lang="en_US"
          />
        </Col>

        <br/>
        <br/>

          <Col xs={12} className="mt-4">
          <TwitterFollowButton
            screenName={'MedniscientAI'}
          />
          </Col>

        </Row>
      </Container>
  </section>

      <FooterCustom />

    </>
  );
};
