import i18n from 'i18next';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en: {
        translations: {
          "About us": "About us",
          "and":"and",
          "before the purchase": "before the purchase",
          "Choose a rank": "Choose a rank",
          "Choose a rank for the dates you wish to sponsor the publication": "Choose a rank for the dates you wish to sponsor the publication",
          "Close": "Close",
          "Congratulations": "Congratulations",
          "Contact us": "Contact us",
          "Great news! We found your article! Let's proceed to the scheduling!": "Great news! We found your article! Let's proceed to the scheduling!",
          "Here you can promote your work by sponsoring any publication during the next 7 days in 3 simple steps! Prices are from 10 to 1 credits (or €) for ranks 1 to 10, then 1 credit (or €) for all ranks": "Here you can promote your work by sponsoring any publication during the next 7 days in 3 simple steps! Prices are from 10 to 1 credits (or €) for ranks 1 to 10, then 1 credit (or €) for all ranks",
          "Hi": "Hi",
          "has already been sponsored":"has already been sponsored",
          "Pay by card": "Pay by card",
          "Please double check that you entered a DOI": "Please double check that you entered a DOI",
          "Please confirm that you read and agree with our": "Please confirm that you read and agree with our",
          "Preview": "Preview",
          "Privacy Policy": "Privacy Policy",
          "of the publication you wish to sponsor on the Alviss application below": "of the publication you wish to sponsor on the Alviss application below",
          "Schedule": "Schedule",
          "Search your publication by DOI": "Search your publication by DOI",
          "Sorry, we could not find the publication referenced": "Sorry, we could not find the publication referenced",
          "Sponsor": "Sponsor",
          "Terms and Conditions": "Terms and Conditions",
          "There was an error with your request! Please see the message below": "There was an error with your request! Please see the message below",
          "Type the Digital Object Identifier": "Type the Digital Object Identifier",
          "Your Alviss credits balance after the purchase": "Your Alviss credits balance after the purchase",
          "Your current Alviss credits balance": "Your current Alviss credits balance",
          "Welcome to the Alviss Sponsors application": "Welcome to the Alviss Sponsors application",
          "When you are ready click the button below": "When you are ready click the button below",
          " with Alviss credits": " with Alviss credits",
          " with payment": " with payment",
          "Pay with Alviss credits": "Pay with Alviss credits",
          "Confirm": "Confirm",
          "Our consulting services are your disposal. Please contact us for more information.": "Our consulting services are your disposal. Please contact us for more information.",
        }
      },
      fr: {
        translations: {
          "About us": "À propos de nous",
          "and":"et",
          "before the purchase": "avant l'achat",
          "Choose a rank": "Choisissez un rang",
          "Choose a rank for the dates you wish to sponsor the publication": "Choisissez un classement pour les dates auxquelles vous souhaitez parrainer la publication",
          "Close": "Fermer",
          "Congratulations": "Félicitations",
          "Contact us": "Contactez nous",
          "Great news! We found your article! Let's proceed to the scheduling!": "Bonne nouvelle! Nous avons trouvé votre article! Passons à l'étape suivante!",
          "Here you can promote your work by sponsoring any publication during the next 7 days in 3 simple steps! Prices are from 10 to 1 credits (or €) for ranks 1 to 10, then 1 credit (or €) for all ranks": "Ici, vous pouvez promouvoir votre travail en parrainant n'importe quelle publication au cours des 7 prochains jours en 3 étapes simples! Les prix vont de 10 à 1 crédits (ou €) pour les rangs 1 à 10, puis 1 crédit (ou €) pour tous les rangs",
          "Hi": "Bonjour",
          "has already been sponsored":"a déjà été sponsorisé",
          "Pay by card": "Payer par carte",
          "Please double check that you entered a DOI": "Veuillez vérifier que vous avez entré un DOI",
          "Please confirm that you read and agree with our": "Veuillez confirmer que vous avez lu et acceptez notre",
          "Preview": "Prévision",
          "Privacy Policy": "Politique de confidentialité",
          "of the publication you wish to sponsor on the Alviss application below": "de la publication que vous souhaitez sponsoriser sur l'application Alviss ci-dessous",
          "Schedule": "Planning",
          "Search your publication by DOI": "Recherchez votre publication par DOI",
          "Sorry, we could not find the publication referenced": "Désolé, nous n'avons pas trouvé la publication référencée",
          "Sponsor": "Sponsoriser",
          "Terms and Conditions": "Conditions générales", 
          "There was an error with your request! Please see the message below": "Il y a eu une erreur avec votre requête! Référez vous au message ci-dessous",
          "Type the Digital Object Identifier": "Tapez l'identifiant d'objet numérique (Digital Object Identifier)",
          "Your Alviss credits balance after the purchase": "Votre solde de crédits Alviss après l'achat",
          "Your current Alviss credits balance": "Votre solde de crédits Alviss actuel",
          "Welcome to the Alviss Sponsors application": "Bienvenue sur l'application Alviss Sponsors",
          "When you are ready click the button below": "Lorsque vous êtes prêt, cliquez sur le bouton ci-dessous",
          " with Alviss credits": " avec les crédits Alviss",
          " with payment": " avec paiement",
          "Pay with Alviss credits": "Payer avec des crédits Alviss",
          "Confirm": "Confirmer",
          "Our consulting services are your disposal. Please contact us for more information.": "Nos services de conseil sont à votre disposition. Contactez-nous pour plus d'informations.",
        }
      },
      ru: {
        translations: {
          "About us": "О нас",
          "and":"и",
          "before the purchase": "перед покупкой",
          "Choose a rank": "Выберите ранг",
          "Choose a rank for the dates you wish to sponsor the publication": "Выберите рейтинг для дат, на которые вы хотите спонсировать публикацию",
          "Close": "Закрыть",
          "Congratulations": "Поздравления",
          "Contact us": "Связаться с нами",
          "Great news! We found your article! Let's proceed to the scheduling!": "Отличные новости! Мы нашли вашу статью! Переходим к следующему шагу!",
          "Here you can promote your work by sponsoring any publication during the next 7 days in 3 simple steps! Prices are from 10 to 1 credits (or €) for ranks 1 to 10, then 1 credit (or €) for all ranks": "Здесь вы можете продвигать свою работу, спонсируя любую публикацию в течение следующих 7 дней за 3 простых шага! Цены от 10 до 1 кредита (или евро) для рангов с 1 по 10, затем 1 кредит (или евро) для всех рангов",
          "Hi": "Привет",
          "has already been sponsored":"уже был спонсирован",
          "Pay by card": "Оплатить картой",
          "Please double check that you entered a DOI": "Пожалуйста, дважды проверьте, что вы ввели DOI",
          "Please confirm that you read and agree with our": "Подтвердите, что вы прочитали и согласны с нашими",
          "Preview": "Просмотр",
          "Privacy Policy": "Политика конфиденциальности",
          "of the publication you wish to sponsor on the Alviss application below": "публикации, которую вы хотите спонсировать, в приложении Alviss ниже",
          "Schedule": "Расписание",
          "Search your publication by DOI": "Найдите свою публикацию по DOI",
          "Sorry, we could not find the publication referenced": "К сожалению, нам не удалось найти публикацию, на которую есть ссылка",
          "Sponsor": "Спонсировать",
          "Terms and Conditions": "Условия и положения",
          "There was an error with your request! Please see the message below": "В вашем запросе произошла ошибка! См. Сообщение ниже",
          "Type the Digital Object Identifier": "Введите идентификатор цифрового объекта DOI)",
          "Your Alviss credits balance after the purchase": "Баланс вашего кредита Alviss после покупки",
          "Your current Alviss credits balance": "Ваш баланс кредитов Alviss",
          "Welcome to the Alviss Sponsors application": "Добро пожаловать в приложение Alviss Sponsors",
          "When you are ready click the button below": "Когда будете готовы, нажмите кнопку ниже",
          " with Alviss credits": " с кредитами Alviss",
          " with payment": " с уплатой",
          "Pay with Alviss credits": "Оплатите кредитами Alviss",
          "Confirm": "Подтвердить",
          "Our consulting services are your disposal. Please contact us for more information.": "Наши консультационные услуги в вашем распоряжении. Пожалуйста, свяжитесь с нами для получения дополнительной информации.",
        }
      }
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false, 
      formatSeparator: ','
    },
    react: {
      // wait: true,
      useSuspense: true,
    }
  });
export default i18n;