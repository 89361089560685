import React, { useState, useEffect, } from "react";
import { useMutation } from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { Sentry, } from "react-activity";
import "react-activity/dist/Sentry.css";

import {  Box, Button, Card, CardContent,
  Divider,  Grid, TextField, 
} from '@mui/material';
import { Col, Row, Container, Image } from '@themesberg/react-bootstrap';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import { RoutesNames } from "../../routes";
import { SEND_MESSAGE_ADMIN } from "../../network/profile";
import { notifConfig, } from "../../components/helpers";
import BgImage from "../../assets/img/illustrations/signin.svg";

const notifyInfo = (message) => toast.info(message, {...notifConfig});
const notifyError = (message) => toast.error(message, {...notifConfig});

export const Bookdemo = () => {
    let history = useNavigate();
    const initialValues = {
      name: '',
      email: '',
      phone: '',
      message: '',
    }
    const [values, setValues] = useState(initialValues);

    const [canSend, setCandSend] = useState(false)

    const [sendMessage, {data, loading, error }] = useMutation (SEND_MESSAGE_ADMIN);

    const handleChange = (event) => {
      setValues({
          ...values,
          [event.target.name]: event.target.value
      });
    };

    const sendMessageGo = () =>{
      if(canSend){
        var mess = [values.name, values.email, values.phone, 'Book a demo: '+values.message].join(' ')
        sendMessage({variables:{message:mess}})
      }
    }

    const SendButton = () => {
      return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }} >
        <Button  disabled={loading || !canSend ? true : false} color="primary" variant="contained" onClick={sendMessageGo}>
        {loading ? <Sentry /> : "Send"}
        </Button>
      </Box>
      )
    }

    useEffect(()=>{
      if(data){
        if(data?.sendMessageAdmin?.status){
          notifyInfo("Your request was sent to our staff! We will be in touch shortly!")
          setValues(initialValues)
          setCandSend(false)
        }else{
          notifyError("Sorry we could not process your request! Please check that you filled all the necessary information!")
        }
      }
    }, [data])

    useEffect(()=>{
      if(values?.name?.length>0 && values?.email?.length>0 && values?.email?.includes('@') && values?.phone?.length>0 && values?.message?.length>0){
        setCandSend(true)
      }
    }, [values])

    return (
        <div component="main" style={{ flex:1, }} sx={{ flexGrow: 1, py: 8 }} >
        <ToastContainer/>
        <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
        <p className="text-center">
        <span style={{ padding:10, borderRadius:5, cursor:'pointer', }} 
            onClick={()=>{
              history(RoutesNames.Presentation.path)
            }}>
            <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
        </span>
        </p>

        <Row className="justify-content-center form-bg-image" 
        // style={{ backgroundImage: `url(${BgImage})` }}
        >
        <Col xs={12} className="d-flex align-items-center justify-content-center">
        <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
          <div className="text-center mb-4 mt-md-0">
            <h3 className="mb-0">Book a demo</h3>

            <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', }}>
            <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
              src="https://www.alviss.ai/static/images/receptionist.jpg" alt="Receptionist"
              style={{ 
                maxWidth:100,
                borderRadius:'50%',
                }} 
              />
            </div>

          </div>

        <Card>
          <CardContent>
            <Grid container spacing={3} >
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  // helperText="Please specify the first name"
                  label="Your name"
                  name="name"
                  onChange={handleChange}
                  required
                  value={values.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  onChange={handleChange}
                  required
                  value={values.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} >
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  onChange={handleChange}
                  required
                  value={values.message}
                  variant="outlined"
                  multiline
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Divider />

        <SendButton/>

        </div>
        </Col>
        </Row>
        </Container>
        </section>
    </div>
    );
};

