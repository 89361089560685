import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';
import { globalStyles, LOCAL } from '../styles/global';
import { Button } from '@mui/material';

import logo from '../assets/img/brand/logo_mai_noback.png';

export const PdfDownloadButtonCt = ({result, patientSelected, ...rest}) => {

    const styles = StyleSheet.create({
        page: {
          backgroundColor: 'white',
          padding: 10,
        },
        section: {
          margin: 5,
          padding: 5,
          flexGrow: 1
        }
      });
    
    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
        <View style={{ 
          borderWidth:1,
          borderColor: 'black', 
          borderStyle:'solid', 
          }}>
          <View style={styles.section}>
            <View style={{ borderBottomColor:'black', borderBottomWidth:1, marginBottom:5,  }}>


          {LOCAL ? null :
            <View style={{ flexDirection:'row', textAlign:'center', justifyContent:'center', margin:5, }}>
              <Image
              src={{ 
                // uri: "https://www.alviss.ai/media/images/brand/logo%20w%20name.png", 
                // uri: "https://www.alviss.ai/static/images/logo_mai_noback.png",
                uri: logo,
                method: "GET", 
              headers: { 
                "Cache-Control": "no-cache", "Access-Control-Allow-Origin": "*", 
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
              }, body: "" }}
              style={{ width:200 }} 
              />
            </View>
          }

          <Text style={[{ textAlign:'center', fontSize:14, marginTop:10, }]}>AI TAVR risk table for {patientSelected}</Text>
          <Text style={{ fontSize:12, marginTop:8, paddingBottom:10, }}>Date: {new Date().toISOString().split('T')[0]}</Text>

          </View>

          {(()=>{
            return result.map((item, key) => {
                return (
                  <View>
                    <Text key={key} style={{ paddingVertical:5, marginTop:5, fontSize:12, }}>
                    {item[0]}:
                    </Text>
                    <View style={{ flexDirection:'row', flexWrap:'wrap', marginVertical:10, }}>
                      {item[1].map((i, k) => {
                        return (
                          <View break style={{ marginTop:5, }}>
                            {/* {i.split(', ').map((i1, k1) => { */}
                            {i.map((i1, k1) => {
                              return (
                                <View style={{ padding:5, marginHorizontal:5,
                                borderColor:'black', 
                                borderWidth:1,
                                width:'100%', 
                                }}>
                                <Text style={{ fontSize:14, }}>{i1}</Text>
                                </View>
                              )
                            })}
                          </View>
                        )
                      })}
                    </View>
                  </View>
                )
              }
                )
            })()}

          {/* <Text style={{ fontSize:10, paddingVertical:30, }}>(*) Mortality: the 30-day rate in medically treated patients in the PARTNER 1B trial was 2.8%.</Text> */}

          </View>
        </View>
        </Page>
      </Document>
    );
  
    return (
      <PDFDownloadLink document={<MyDocument />} fileName="AI TAVR report.pdf" >
          <Button color="primary" variant="outlined"
          size="large"
          style={{ textTransform:'none', }}
          > 
            Download report
          </Button>
      </PDFDownloadLink>
    )
  }