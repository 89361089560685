import { gql } from '@apollo/client';

export const UPLOAD_CTTAVR = gql`
    mutation UploadCttavr($file: Upload!) {
        uploadCttavr(file:$file){
            result
        }
    }
`

export const UPLOAD_CTTAVR_RESEARCH = gql`
    mutation UploadCttavrResearch($file: Upload!) {
        uploadCttavrResearch(file:$file){
            message
        }
    }
`

export const UPLOAD_CTPE = gql`
    mutation UploadCtpe($file: Upload!) {
        uploadCtpe(file:$file){
            result
        }
    }
`


export const AUTOCOMPLETION_PATIENT = gql`
  mutation AutocompletionPatient($query: String!) {
      autocompletionPatient(query:$query) {
        suggestions
      }
  }
`


