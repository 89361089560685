import { gql } from '@apollo/client';

export const ME_QUERY = gql`
    query meQuery{
        me {
            username,
            verified
        }
    }
`

export const LOGIN_USER = gql`
  mutation($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      success
      errors
      token
      refreshToken
      unarchiving
      user {
        id
        username
        email
        firstName
        lastName
        verified
      }
    }
  }
`

export const LOGIN_USER_EMAIL = gql`
  mutation($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      success
      errors
      token
      refreshToken
      unarchiving
      user {
        id
        username
        email
        firstName
        lastName
        verified
      }
    }
  }
`

export const REGISTER_USER = gql`
  mutation($username: String!, $email: String!, $password1: String!, $password2: String!) {
    register(
      username: $username, 
      email: $email, 
      password1: $password1, 
      password2: $password2
      ) {
      success,
      errors,
      token,
      refreshToken
    }
  }
`

export const CREATE_INSTITUTIONAL_ACCOUNT = gql`
    mutation CreateInstitutionalAccount($institution:String!, $username:String!){
        createInstitutionalAccount(institution:$institution, username:$username){
            status
        }
    }
`

export const FETCH_INSTITUTIONAL_ACCOUNT = gql`
    mutation {
        fetchInstitutionalAccount{
          institution{
            id
            name
            accessKey
            country
            expertAccess
            editorialAccess
            articleAccess
          }
        }
    }
`

export const FETCH_INSTITUTIONAL_EMPLOYEE = gql`
    mutation {
        fetchInstitutionalEmployee{
          employee {
            id
            institution
            title
            image
            user{
                id
                username
                email
                firstName
                lastName
            }
          }
        }
    }
`

