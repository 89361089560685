import { ApolloClient, InMemoryCache, HttpLink, createHttpLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client'

import { setContext } from '@apollo/client/link/context';
import { getToken } from './auth';
import { LOCAL } from '../styles/global';

// dev

const httpLinkDev = createUploadLink ({
  uri: 'http://127.0.0.1:9000/graphql/',
  });

// prod

const httpLinkProd = createUploadLink ({
  uri: `https://www.medniscientai.com/graphql/`,
  });

const httpLink = LOCAL ? httpLinkDev : httpLinkProd

export const DEFAULT_USER_KEY = 'wr9JsSk7wfkIQbGJ4yQXoXPsYdWaDOYrgv6_qq80hSM'

const authLink = setContext(async (_, { headers }) => {
  const tokenUser = getToken();
  const token = tokenUser ? tokenUser : DEFAULT_USER_KEY
  // const token = tokenUser
  return {
      headers: {
          ...headers,
          authorization: token ? `JWT ${token}` : "",
      }
  }
});

const linkAuth = authLink.concat(httpLink)

export const client = new ApolloClient({
  link: linkAuth,
  cache: new InMemoryCache()
});
