import React, { useState, useEffect, } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCheckCircle, faFolder,   } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Card, Image, Container, } from '@themesberg/react-bootstrap';
import {  Button } from '@mui/material';

import { RoutesNames } from "../routes";
import { FooterCustom } from "./dashboard/FooterCustom";
import ResponsiveAppBar from "./dashboard/ResponsiveAppBar";

import happy from '../assets/img/brand/happy_old_man1.jpg';
import happy1 from '../assets/img/brand/happy_old_woman1.jpg';

export const AITAVR = () => {
  let history = useNavigate();

  return (
    <>
      <ResponsiveAppBar/>

      <section className="section">
        <Container>
            <Row className="justify-content-between align-items-center">
              <Col lg={5} className="order-lg-1 text-center">
                <br/>
                <br/>
                <h2 className="mb-1 fw-bold" style={{ color:'black', }}>AI TAVR for structural heart cardiologists and surgeons.</h2>

                <br/>
                <h4 className="mb-2" style={{ color:'black', }}>AI TAVR enhances clinicians with the ability to identify patients at high risk of stroke.</h4>
                <br/>
                <p className="mb-2" style={{ color:'black', }}>AI TAVR is an artificial intelligence that reads CT scans to predict the risk of surgical complications, like stroke.</p>

                <br/>
                <Button onClick={() => history(RoutesNames.Bookdemo.path)} 
                variant="contained" 
                style={{ borderRadius:30, textTransform:'none', }}>
                Book a free demo
                </Button>
                <br/>
                <br/>
              </Col>
              <Col lg={6} className="order-lg-2 p-1 mt-2">
                <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
                  // src="https://www.alviss.ai/media/images/woman_gazing.jpg" alt="TAVR"
                  src={happy} alt="AI TAVR"
                  style={{ 
                    maxWidth:500,
                    width:'100%',
                    borderTopLeftRadius:40,
                   }} 
                  />
              </Col>
            </Row>
        </Container>
      </section>


      <section className="section">
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col lg={5} className="order-lg-1">
              <h4>Why using AI TAVR</h4>
              <br/>
              <p className="mb-1"><strong>Stroke</strong> occurs in approximately <strong>3% of transcatheter aortic valve replacement (TAVR) procedures</strong>.</p>
              <p className="mb-1">Even experienced operators cannot predict stroke.</p>
              <p className="mb-1"><strong>AI TAVR uses deep learning and computer vision to predict 9 out of 10 strokes</strong> related to TAVR.</p>
              <p className="mb-1">Predicting stroke during procedure planning could allow tailoring procedures to reduce the risk of stroke.</p>
              <p className="mb-1">AI TAVR also predicts other surgical risks.</p>
              <br/>
              <Button onClick={() => history(RoutesNames.Bookdemo.path)} 
                variant="contained" 
                style={{ borderRadius:30, textTransform:'none', }}>
                Book a free demo
              </Button>
              <br/>
              <br/>
            </Col>
            <Col lg={6} className="order-lg-2 text-center p-1 mt-2">
              <br/>
              <Image className="shadow-lg embed-responsive embed-responsive-16by9" 
              style={{ maxWidth:500,
                borderBottomLeftRadius:40,
               }} 
              // src="https://www.alviss.ai/static/images/happy_old_man.png"
              src={happy1} alt="AI TAVR patient"
              />
                <br/>
            </Col>
          </Row>
        </Container>
      </section>

      <section style={{ backgroundColor:'#1764c0', }}>
        <br/>
        <br/>
        <Container>
          <Row className="justify-content-between align-items-center pb-2">
            <h4 className="text-center" style={{ color:'white', }}>Our cloud native technology can be integrated in your electronic medical records software.</h4>
            <br/>
            <br/>
            <Col lg={12}>
              <p className="mb-3 mt-2 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                But AI TAVR doesn't need EMR integration. It is an end to end AI that reads CT scan images automatically.
              </p>
              <p className="mb-3 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                AI TAVR has an API-ready system.
              </p>
              <p className="mb-3 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                AI TAVR has a ready-to-use Web application.
              </p>
              <p className="mb-3 text-white">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
                AI TAVR can be easily integrated into your PACS or CT processing software.
              </p>
              <p className="mb-1 text-white">AI TAVR is the first predictive medicine solution for heart surgery and interventional cardiology.</p>
            </Col>
          </Row>
        </Container>
        <br/>
        <br/>
      </section>

      <section className="section">
        <Container>
          <Row className="justify-content-between align-items-center">
            <h4 className="text-center p-2" style={{ color:'black', }}>Our science</h4>
            <Col lg={12}>
              <p className="mb-1"><strong>"Deep learning-based prediction of early cerebrovascular events after transcatheter aortic valve replacement."</strong></p>
              <p className="mb-1">More than 1 million data points were used to train the AI TAVR deep learning predictive model.</p>
              <br/>
              <Button onClick={() => window.location.href = 'https://www.nature.com/articles/s41598-021-98265-5'} 
                variant="contained" 
                style={{ borderRadius:30, textTransform:'none', }}>
                Read the article
              </Button>
            </Col>
          </Row>
        </Container>
      </section>


      <FooterCustom />

    </>
  );
};
