import * as React from 'react';

import { useNavigate } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import MenuItem from '@mui/material/MenuItem';

import { HashLink } from 'react-router-hash-link';
import Nav from 'react-bootstrap/Nav';
import { RoutesNames } from '../../routes';
import { AuthContext } from '../../config/auth';

const pages = ['AI PE', 'Research',  ];
const hashLinks = {'AI PE': RoutesNames.Ctpe.path,
'Research': RoutesNames.CtpeResearch.path, };

export const NavbarInternalPE = () => {
  let history = useNavigate();
  const { logout, } = React.useContext(AuthContext)

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" color='transparent' elevation={0}>
      <Container maxWidth="xl">
        <Toolbar disableGutters className='justify-content-space-between'>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                // display: { xs: 'block', md: 'none' },
                display: { xs: 'block', },
              }}
            >

              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Nav.Link eventKey={1} as={HashLink} to={hashLinks[page]} 
                    style={{ fontSize:18 }}>
                    <Typography textAlign="center" style={{ color:'black', }}>{page}</Typography>
                  </Nav.Link>
                </MenuItem>
              ))}

            <MenuItem key={999} onClick={handleCloseNavMenu}>
              <Nav.Link eventKey={999} onClick={()=>{
                    history('/')
                    logout()
                  }} 
                style={{ fontSize:18 }}>
                <Typography textAlign="center" style={{ color:'black', }}>Logout</Typography>
              </Nav.Link>
            </MenuItem>

            </Menu>
          </Box>


        </Toolbar>
      </Container>
    </AppBar>
  );
}
