
export const RoutesNames = {
    Presentation: { path: "/" },
    Bookdemo: { path: "/bookdemo" },
    Signin: { path: "/signin" },
    Signup: { path: "/signup" },
    
    AITAVR: { path: "/aitavr" },
    AIPE: { path: "/aipe" },
    Cttavr: { path: "/cttavr" },
    CttavrResearch: { path: "/research" },
    Ctpe: { path: "/ctpe" },
    CtpeResearch: { path: "/researchpe" },
    Press: { path: "/press" },
    Terms: { path: "/terms" },
    Privacy: { path: "/privacy" },
    Team: { path: "/team" },

    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },
    Lock: { path: "/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },
};